/* You can add global styles to this file, and also import other style files */
input[type="text"]
  display: block
  margin-bottom: 5px

body
  font-family: arial


.activeLink
  color: #000080
  font-weight: bolder
  text-decoration: underline

code.mark
  background-color: yellow
  font-size: 12px

// expliciet deze waardes want material maakte H3 heel klein
h3
  margin-top: 25px !important
  margin-bottom: 5px !important
  font-size:28px !important
  font-weight: 500 !important
  line-height:33.6px !important

img.large
  width:750px

img.medium
  width:650px

img.small
  width:500px

img
  border: 1px solid #AAA
  margin-bottom: 25px

.actiefMenu
  font-weight: bold

.dikkehr
  border: 3px solid blue

.extra1
  background-color: #e86443

.extra2
  background-color: #32d943

.pink
  background-color:#d63384
  color:#000000

.orange
  background-color:#fd7e14
  color:#000000

.teal
  background-color:#20c997
  color:#000000

.indigo
  background-color:#6610f2
  color:#ffffff

.red
  background-color:#dc3545
  color:#ffffff

.yellow
  background-color:#ffc107
  color:#000000

// --bs-blue: #0d6efd;
// --bs-indigo: #6610f2;
// --bs-purple: #6f42c1;
// --bs-pink: #d63384;
// --bs-red: #dc3545;
// --bs-orange: #fd7e14;
// --bs-yellow: #ffc107;
// --bs-green: #198754;
// --bs-teal: #20c997;
// --bs-cyan: #0dcaf0;
